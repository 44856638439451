import {
    UPDATE,
    ADD,
    db,
} from '@/firebase.js'

export default {
    data: function () {
        return {
            showExplanationModal: false,
            confId: null,
            referralCode: null,
            sentReferralCode: false,
            chaptersDone: false,
            showSignupDone: false,

        }
    },
    methods: {
        verifyEmail: function () {
            UPDATE(db.doc('users/' + this.confId), {
                verified: true
            })
            this.showPasswordPrompt = true
            /*             firebase.auth().onAuthStateChanged((user) => {
                            this.showPasswordPrompt = (!user) ? true : false
                        }); */
        },
        addReferralCode: function () {
            if (!this.referralCode) return
            let lowercaseCode = this.referralCode.toLowerCase()
            UPDATE(db.doc('users/' + this.confId), {
                referral: lowercaseCode
            }).then(() => {
                this.sentReferralCode = 'success'
                setTimeout(() => {
                    this.showSignupDone = true
                }, 1000)
            }).catch(() => {
                let col = db.collection('failed_referrals')
                ADD(col, {
                    user: this.confId,
                    referral: lowercaseCode
                }).then(() => {
                    this.showSignupDone = true
                })
            })
        },
        addNoReferralCode: function () {
            UPDATE(db.doc('users/' + this.confId), {
                referral: false
            })
            this.showSignupDone = true
        }
    },
    mounted: function () {
        this.confId = this.$route.query.confid
        this.email = this.$route.query.email
        if (this.confId && !this.$route.query.auth) this.verifyEmail()
    }
}